import React, { useMemo } from "react";
import _get from "lodash/get";
import { Box, ActivityIndicator, Table } from "@thenounproject/lingo-core";
import styled from "styled-components";

import EmptyState from "../EmptyState";
import ReportTitle from "../report/ReportTitle";
import { InfiniteTableProps } from "./types";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";

const TableContainer = styled(Box).attrs({
  borderTop: "default",
  pb: "100",
})``;

export const dataTableRowHeights = { small: 40, medium: 80, large: 136 };

function DataTable<DataType>({
  columns,
  data,
  isLoading,
  hasMorePages,
  sort,
  onSort,
  reportTitleProps,
  fetchNextPage,
  onRowClick,
  emptyState,
  selectionProps,
  tableError,
  renderContextMenuItems,
  isLibraryView = false,
}: InfiniteTableProps<DataType>) {
  const isEmpty = useMemo(
    () => !isLoading && !tableError && data?.length === 0,
    [data?.length, isLoading, tableError]
  );
  const { assetRowSize } = useAssetLibraryContext();

  function renderTable() {
    return (
      <Table
        rowSize={isLibraryView ? assetRowSize : "medium"}
        hasMorePages={hasMorePages}
        tableData={{ data, isLoading, error: tableError, fetchNextPage }}
        onRowClick={onRowClick}
        columns={columns}
        checkboxProps={selectionProps}
        renderContextMenuItems={renderContextMenuItems}
        sort={sort}
        onSort={onSort}
      />
    );
  }

  const renderContent = () => {
    if (isEmpty) {
      if (emptyState) return emptyState;
      return (
        <Box>
          <EmptyState
            styleOverrides={{ py: "xxl" }}
            title="No data"
            subtitle="There is nothing to show for the current period."
          />
        </Box>
      );
    } else if (isLoading && !data?.length) {
      return <ActivityIndicator mt="xxl" center />;
    } else if (tableError && !data?.length) {
      return (
        <EmptyState
          title="Unable to load data"
          subtitle={tableError?.message}
          iconProps={{ iconId: "error", fill: "error" }}
          button={{ text: "Retry", onClick: fetchNextPage }}
        />
      );
    } else {
      return renderTable();
    }
  };

  return (
    <TableContainer>
      {Boolean(reportTitleProps) && (
        <ReportTitle {...reportTitleProps} styleOverrides={{ px: "40px" }} />
      )}
      <div style={{ position: "relative", width: "100%", overflowX: "auto" }}>
        {renderContent()}
      </div>
    </TableContainer>
  );
}

export default React.memo(DataTable) as typeof DataTable;
