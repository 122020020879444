import React, { useEffect, useState, useMemo } from "react";
import {
  Portal,
  PagingIndicator,
  PopupMenu,
  ToggleBox,
  Button,
  Icon,
  Flex,
  Tooltip,
  factory,
} from "@thenounproject/lingo-core";
import KitSelectList from "../kits/KitSelectList";
import usePortalKits from "@redux/actions/portals/usePortalKits";
import EmptyState from "../EmptyState";

type Props = {
  portal: Portal;
  selectPortal: () => void;
  isOpen: boolean;
  selectedKitRoles: Record<string, string[]>;
  selectKitRoles: (kitIds: string[], role: string) => void;
  multiSelectPortalRoles: (portalId: string, role: string) => void;
  allowPortalKits?: boolean;
  variedPortals?: string[]; // allows for custom toggle box control UI
};

type VariedControlProps = {
  portalId: string;
  selectPortalRole: (portalId: string, role: string) => void;
  setRoleMenuVisible: (visible: boolean) => void;
  roleMenuVisible: boolean;
};

function VariedControlComponent({
  portalId,
  selectPortalRole,
  setRoleMenuVisible,
  roleMenuVisible,
}: VariedControlProps) {
  return (
    roleMenuVisible && (
      <PopupMenu
        source={`${portalId}-role-menu`}
        close={() => setRoleMenuVisible(false)}
        width={280}
        vPos="alignTop">
        <PopupMenu.Item
          title="Viewer"
          checked
          onClick={() => selectPortalRole(portalId, "guest")}
        />
        <PopupMenu.Item
          title="No Access"
          checked
          onClick={() => selectPortalRole(portalId, "revoked")}
        />
      </PopupMenu>
    )
  );
}

const PortalToggleBox = ({
  portal,
  selectPortal,
  isOpen,
  selectedKitRoles,
  selectKitRoles,
  multiSelectPortalRoles,
  variedPortals,
  allowPortalKits,
}: Props) => {
  const [roleMenuVisible, setRoleMenuVisible] = useState(false),
    [contentVisible, setContentVisible] = useState(false),
    {
      data: { kits = [], total = 0 } = {},
      isLoading,
      fetchNextPage,
      error,
    } = usePortalKits({
      portalId: portal.id,
    }),
    hasMorePages = kits?.length < total,
    isVariedPortal = variedPortals?.includes(portal.id);

  useEffect(() => {
    if (!isOpen) {
      setContentVisible(false);
    }
  }, [isOpen]);

  function renderKitList() {
    if (!allowPortalKits) return null;

    if (!kits.length)
      return (
        <EmptyState
          styleOverrides={{ py: "none" }}
          title="No Kits"
          subtitle="Kits inserted into the portal will appear here"
          size="small"
        />
      );

    return (
      <>
        <KitSelectList
          kits={kits}
          selectedKitRoles={selectedKitRoles}
          selectRole={selectKitRoles}
        />
        <PagingIndicator
          data={kits}
          isLoading={isLoading}
          hasMore={hasMorePages}
          fetchNextPage={fetchNextPage}
          error={error}
        />
      </>
    );
  }
  const hasEditorStatusInPortal = kits.some(kit =>
    selectedKitRoles[kit.kitId]?.includes("collaborator")
  );

  const tooltipId = useMemo(() => factory.genUUID(), []);
  const expandButtonContent = useMemo(() => {
    if (!allowPortalKits || !isOpen) return null;

    const tooltip = (
      <Flex
        data-tooltip-source={tooltipId}
        alignItems="center"
        justifyContent="center"
        borderRight="1px solid grayLight"
        pr="8px"
        style={{ cursor: "pointer" }}>
        <Icon iconId="action.edit" fill="grayDark" height="20px" width="20px" />
        <Tooltip source={tooltipId} direction="top" maxWidth={250}>
          Members with edit access will incur billing charges.{" "}
          <Button
            buttonStyle="tertiary"
            themeOverrides={{ primaryColor: "white", primaryColorDark: "white" }}
            fontStyle="ui.smallBold"
            text="Learn more."
            newWindow
            link="https://help.lingoapp.com/en/articles/10908457-what-is-an-editor"
          />
        </Tooltip>
      </Flex>
    );
    return (
      <Flex alignItems="center" justifyContent="center" gap="8px">
        {hasEditorStatusInPortal && tooltip}
        <Button
          buttonStyle="tertiary"
          text={contentVisible ? "Hide all kits" : "Show all kits"}
          onClick={() => setContentVisible(!contentVisible)}
        />
      </Flex>
    );
  }, [allowPortalKits, isOpen, contentVisible, tooltipId, hasEditorStatusInPortal]);

  return (
    <>
      <ToggleBox
        title={portal.name}
        isOn={isOpen}
        isOpen={contentVisible && isOpen && !isVariedPortal}
        action={expandButtonContent}
        estimatedHeight={kits.length * 50 + 100}
        onToggle={selectPortal}
        control={isVariedPortal ? "button" : "switch"}
        buttonProps={{
          buttonStyle: "tertiary",
          text: "Mixed Access",
          icon: "triangle-double",
          size: "small",
          onClick: () => setRoleMenuVisible(true),
          dataPopupSource: `${portal.id}-role-menu`,
        }}>
        {renderKitList()}
      </ToggleBox>
      <VariedControlComponent
        portalId={portal.id}
        selectPortalRole={multiSelectPortalRoles}
        setRoleMenuVisible={setRoleMenuVisible}
        roleMenuVisible={roleMenuVisible}
      />
    </>
  );
};

export default PortalToggleBox;
