import React, { useCallback, useState } from "react";

import {
  Flex,
  PopupMenu,
  Text,
  Button,
  PortalMember,
  Portal,
  ActivityIndicator,
  Box,
  SpacePermission,
  PortalPermission,
} from "@thenounproject/lingo-core";

import useRemovePortalMember from "@redux/actions/portalMembers/useRemovePortalMember";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useGetUser } from "@selectors/getters";

import { AvatarLarge } from "../../kits/modals/ShareKitModal/Avatars";
import { getSpaceRoleDisplayName } from "@constants/displayUserRoles";
import usePortalMembers from "@redux/actions/portalMembers/usePortalMembers";
import EmptyState from "../../EmptyState";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type Props = {
  portal: Portal;
};
const PortalMemberList: React.FC<Props> = ({ portal }) => {
  const { data: members, isLoading, error } = usePortalMembers({ portalId: portal.id });
  const currentUser = useGetUser();
  const space = useSelectSpace(portal.spaceId);
  const [memberMenuVisible, setActiveMemberMenu] = useState<number>(null);
  const { showModal } = useShowModal();
  const [_removePortalMember] = useRemovePortalMember();
  const canEditMembers = portal.access?.permissions?.includes(PortalPermission.edit);
  const canManageSpaceRoles = space?.access?.permissions?.includes(SpacePermission.changeUserRole);
  // MARK : Actions
  // -------------------------------------------------------------------------------
  const removePortalMember = useCallback(
    async (userId: number) => {
      await _removePortalMember({
        userId,
        portalId: portal.id,
      });
    },
    [_removePortalMember, portal.id]
  );

  function showMemberMenu(id: number) {
    setActiveMemberMenu(memberMenuVisible === id ? null : id);
  }

  // MARK : Rendering
  // -------------------------------------------------------------------------------
  function renderMemberMenu(m: PortalMember) {
    return (
      <PopupMenu source={`${m.id}-menu`} close={() => showMemberMenu(m.userId)}>
        <PopupMenu.Item
          title={canManageSpaceRoles ? "Change role & access" : "Change access"}
          disabled={
            m.spaceRole === "owner" || (!canManageSpaceRoles && m.spaceRole !== "limited_member")
          }
          onClick={() =>
            showModal(ModalTypes.SPACE_MEMBER_PERMISSIONS, {
              spaceId: portal.spaceId,
              userIds: [m.userId],
            })
          }
        />
        <PopupMenu.Item
          title="Remove from portal"
          onClick={() => removePortalMember(m.userId)}
          disabled={m.spaceRole !== "limited_member"}
        />
      </PopupMenu>
    );
  }

  function renderMember(m: PortalMember) {
    const menuVisible = memberMenuVisible === m.userId,
      isRevoked = m.status === "revoked",
      { user, spaceRole } = m;

    return (
      <Flex id="test" key={`member-${m.id}`} py="s" alignItems="center">
        <AvatarLarge
          src={user.avatar}
          alt={`Avatar for ${user.name}`}
          style={isRevoked ? { opacity: ".25" } : {}}
        />
        <Flex flex="3" flexDirection="column">
          <Text truncate textDecoration={isRevoked ? "line-through" : null}>
            {user.name}
          </Text>
          <Text
            font="ui.small"
            color="grayDarkest"
            truncate
            textDecoration={isRevoked ? "line-through" : null}>
            {user.email}
          </Text>
        </Flex>
        {space && canEditMembers && (
          <Text
            flex="1"
            color="grayDarkest"
            font="ui.small"
            textDecoration={isRevoked ? "line-through" : null}>
            {getSpaceRoleDisplayName(spaceRole, space)}
          </Text>
        )}
        {canEditMembers && (
          <Flex flex="1" alignItems="center" justifyContent="flex-end">
            <Button
              disabled={
                spaceRole === "owner" ||
                m.userId === currentUser?.id ||
                (!canManageSpaceRoles && m.spaceRole !== "limited_member")
              }
              data-popup-source={`${m.id}-menu`}
              icon="navigation.overflow"
              buttonStyle="tertiary"
              size="small"
              onClick={() => showMemberMenu(m.userId)}
            />
          </Flex>
        )}
        {menuVisible ? renderMemberMenu(m) : null}
      </Flex>
    );
  }

  const memberCount = members?.members?.length;
  if (memberCount === 0) {
    return null;
  } else if (isLoading && !memberCount) {
    return (
      <Box height="100px" mt="m">
        <ActivityIndicator center />
      </Box>
    );
  } else if (error && !memberCount) {
    return (
      <EmptyState
        styleOverrides={{ mt: "m" }}
        title="Error loading members"
        subtitle={error.message}
        size="small"
      />
    );
  }
  return (
    <Flex flexDirection="column" mt="m">
      {members.members?.map(renderMember)}
    </Flex>
  );
};

export default PortalMemberList;
