import React, { useEffect, useRef } from "react";
import { ItemType, Item } from "@thenounproject/lingo-core";
import { useAppSelectorV1 } from "@redux/hooks";

import KeyCode from "../../constants/keyCodes";
import { getVersionedItemId, scrollItemOnScreen } from "@helpers/items";

const isAssetType = (item: Item) => item && item.type === ItemType.asset,
  isFullWidthAssetType = (item: Item) =>
    item && item.asset && item.data.displayStyle === "full_width";

const determineNavigationDirection = e => {
  switch (e.key) {
    case KeyCode.leftArrow:
    case KeyCode.upArrow:
      return -1;
    case KeyCode.rightArrow:
    case KeyCode.downArrow:
      return 1;
    default:
      return 0;
  }
};

type Props = {
  items: Item[];
  selectedIndex: number;
  assetsPerRow: number;
  onSelectItem: (e: KeyboardEvent, id: string, items: Item[]) => void;
  onTogglePreview: (item: Item) => void;
};

const KeyboardHandler: React.FC<Props> = ({
  items,
  selectedIndex,
  assetsPerRow,
  onSelectItem,
  onTogglePreview,
}) => {
  /**
   * If there is a modal open, we want to prevent
   * interference with other keyboard handlers.
   */
  const modals = useAppSelectorV1(state => state.modals);
  const modalIsOpen = Boolean(modals.length);

  const blockIndexes = useRef<Record<number, number>>({});
  useEffect(() => {
    blockIndexes.current = {};
  }, [items]);

  useEffect(() => {
    if (modalIsOpen) return;

    function detectArrowKeys(e: KeyboardEvent) {
      const inputTargets = ["input", "textarea", "[role=textbox]"];
      if (inputTargets.some(t => (e.target as HTMLElement).matches(t))) return;

      if (e.key === KeyCode.space) {
        e.preventDefault();
        e.stopPropagation();
        if (selectedIndex === null) return;
        const item = items[selectedIndex];
        onTogglePreview(item);
        return;
      }

      const determineAssetJumpNum = () => {
        switch (e.key) {
          case KeyCode.leftArrow:
          case KeyCode.rightArrow:
            return 1;
          case KeyCode.upArrow:
          case KeyCode.downArrow:
            if (isFullWidthAssetType(items[selectedIndex])) return (assetsPerRow + 1) / 2;
            else return assetsPerRow;
          default:
        }
      };
      const assetBlockIndex = (index: number) => {
        const calculate = () => {
          if (!items[index] || !isAssetType(items[index]) || isFullWidthAssetType(items[index])) {
            return -1;
          } else return assetBlockIndex(index - 1) + 1;
        };

        if (typeof blockIndexes.current[index] === "undefined") {
          blockIndexes.current[index] = calculate();
        }
        return blockIndexes.current[index];
      };

      const rowFromAssetBlock = index => Math.floor(assetBlockIndex(index) / assetsPerRow);

      const isSkippingARowFromFullWidth = index =>
        isFullWidthAssetType(items[selectedIndex]) &&
        rowFromAssetBlock(index) !== rowFromAssetBlock(selectedIndex - 1);

      const incrementDirection = determineNavigationDirection(e),
        assetJumpNum = determineAssetJumpNum();

      if (incrementDirection === 0) return;

      e.preventDefault();

      let newIndex: number;
      // Attempt to navigate in the prescribed direction
      for (
        newIndex = selectedIndex + incrementDirection;
        Math.abs(newIndex - selectedIndex) < assetJumpNum &&
        isAssetType(items[newIndex]) &&
        !isFullWidthAssetType(items[newIndex]) &&
        !isSkippingARowFromFullWidth(newIndex + incrementDirection);
        newIndex += incrementDirection
      );

      // Back up by one if we skipped a row in our asset block
      if (
        newIndex - incrementDirection !== selectedIndex &&
        (!isAssetType(items[newIndex]) || isFullWidthAssetType(items[newIndex]))
      ) {
        const indexBeforeAbnormality = newIndex - incrementDirection;
        if (rowFromAssetBlock(selectedIndex) !== rowFromAssetBlock(indexBeforeAbnormality)) {
          newIndex = indexBeforeAbnormality;
        }
      }

      // Continue searching in the given direction if we encountered non-assets
      if (!isAssetType(items[newIndex])) {
        for (
          newIndex += incrementDirection;
          items[newIndex] && !isAssetType(items[newIndex]);
          newIndex += incrementDirection
        );
      }

      const newSelected = items[newIndex];
      if (newSelected) {
        onSelectItem(e, getVersionedItemId(newSelected), items);

        scrollItemOnScreen(newSelected.shortId);
      }
    }

    window.addEventListener("keydown", detectArrowKeys);
    return () => {
      window.removeEventListener("keydown", detectArrowKeys);
    };
  }, [items, selectedIndex, assetsPerRow, onSelectItem, modalIsOpen, onTogglePreview]);

  return null;
};

export default KeyboardHandler;
