import React from "react";
import SearchResult from "./SearchResult";
import {
  Box,
  Space,
  PagingIndicator,
  LingoError,
  ActivityIndicator,
  Portal,
} from "@thenounproject/lingo-core";
import EmptyState from "../EmptyState";

function renderError(error: LingoError) {
  return (
    <EmptyState
      iconProps={{ iconId: "info.error", fill: "error", mt: "none", size: "60" }}
      title={error.message}
      size="small"
    />
  );
}
function renderEmptyState() {
  return (
    <EmptyState
      title="No results"
      subtitle="Try using broader search terms, checking for typos or changing your filters."
      size="small"
      iconProps={{
        iconId: "navigation.search",
        fill: "grayDark",
        mt: "none",
        size: "60",
      }}
    />
  );
}

type Props = {
  results: {
    results: any[];
    error: null | LingoError;
    total: number;
    offset: number;
    limit: number;
  };
  space: Space;
  portal: Portal;
  isLoading: boolean;
  fetchNextPage: () => void;
  error: null | LingoError;
};

export const AssetResultList = ({
  results,
  space,
  portal,
  isLoading,
  fetchNextPage,
  error,
}: Props) => {
  const hasMorePages = results?.results?.length < results?.total;

  if (error) return renderError(error);
  if (isLoading && !results?.results.length) return <ActivityIndicator center />;
  if (!results?.results?.length) return renderEmptyState();
  return (
    <Box pb="m" style={{ overflowY: "auto" }}>
      {results?.results?.map(item => (
        <SearchResult key={item.id} item={item} space={space} portal={portal} />
      ))}
      {hasMorePages && (
        <PagingIndicator
          data={results?.results}
          isLoading={isLoading}
          hasMore={hasMorePages}
          fetchNextPage={fetchNextPage}
          error={error}
        />
      )}
    </Box>
  );
};

export const JumpToResultList = ({
  results,
  space,
  portal,
  isLoading,
  fetchNextPage,
  error,
}: Props) => {
  const hasMorePages = results?.offset + results?.limit < results?.total;
  if (error) return renderError(error);
  if (!results?.results?.length) return renderEmptyState();

  const typeMap = {
    gallery: "item",
  };
  return (
    <Box pb="m" style={{ overflowY: "auto" }}>
      {results?.results?.map(jump => {
        const key = typeMap[jump.type] ?? jump.type;
        const passProps = { [key]: jump.object };
        return (
          <SearchResult
            key={jump.object.id || jump.object.kitId}
            {...passProps}
            space={space}
            portal={portal}
          />
        );
      })}
      {hasMorePages && (
        <PagingIndicator
          data={results?.results}
          isLoading={isLoading}
          hasMore={hasMorePages}
          fetchNextPage={fetchNextPage}
          error={error}
        />
      )}
    </Box>
  );
};
