import React, { Fragment, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import {
  Flex,
  Box,
  Text,
  Icon,
  Checkbox,
  ImageView,
  SearchField,
  Notice,
  Kit,
  PrivacyLevel,
  KitCollectionItem,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalBody from "../../../components/ModalBody";
import EmptyState from "../../../components/EmptyState";

import useKits from "@actions/kits/useKits";
import useAddKitToCollection from "@redux/actions/kitCollections/useAddKitToCollection";
import useRemoveKitFromCollection from "@redux/actions/kitCollections/useRemoveKitFromCollection";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useKitCollection from "@redux/actions/kitCollections/useKitCollection";

const KitItem = styled(Box).attrs({
  as: "li",
  mb: "m",
  borderBottom: "default",
})`
  list-style: none;
  align-items: center;
`;

const KitDetails = styled(Flex).attrs({
  mb: "m",
  justifyContent: "space-between",
  alignItems: "center",
})``;

const KitCover = styled(ImageView).attrs({
  mr: "m",
  height: "50px",
  width: "50px",
  flexBasis: "50px",
  flexShrink: 0,
  borderRadius: "default",
  alignItems: "center",
  justifyContent: "center",
  background: "grayLighter",
})``;

const KitName = styled(Text).attrs({
  textAlign: "left",
  flexGrow: 0.6,
  font: "ui.regularBold",
  flexBasis: "100px",
  truncate: true,
})``;

const PrivacyDetail = styled(Flex).attrs({
  flexGrow: 0.4,
  width: "50px",
  alignItems: "center",
})``;

type Props = {
  kitCollectionId: string;
};

const ManageCollectionKitsModal: React.FC<Props> = ({ kitCollectionId }) => {
  const iconMap = {
    [PrivacyLevel.public]: "globe",
    [PrivacyLevel.private]: "users",
    [PrivacyLevel.password]: "globe-lock",
  };

  const space = useSelectSpace();
  const {
    data: kitCollection,
    isLoading: isLoadingCollection,
    error: collectionLoadError,
  } = useKitCollection({ collectionId: kitCollectionId });
  const {
    data: kitsData,
    isLoading: isLoadingKit,
    error: kitLoadError,
  } = useKits({ spaceId: space.id });
  const { kits = [] } = kitsData || {};

  const isLoading = isLoadingKit || isLoadingCollection,
    loadingError = kitLoadError || collectionLoadError;

  const kitIds = useMemo(() => {
    const items = (kitCollection?.items as KitCollectionItem[]) ?? [];
    return items.map(item => (typeof item.kit === "string" ? item.kit : item.kit.kitId));
  }, [kitCollection?.items]);

  const collectionName = kitCollection?.name || "Unnamed Collection";

  const [addKitToCollection] = useAddKitToCollection(),
    [removeKitFromCollection] = useRemoveKitFromCollection();

  const [error, setError] = useState(null),
    [filterQuery, setFilterQuery] = useState<string>(undefined);

  const filterKits = (query: string, kits: Kit[]): Kit[] => {
    return kits.filter(k => k.name.toUpperCase().includes(query.toUpperCase()));
  };

  const onFilterResults = useCallback((query?: string) => {
    setFilterQuery(query);
  }, []);

  const debouncedOnFilterResults = _debounce(onFilterResults, 250, {
    // Only run filter on final keystroke of interval
    leading: false,
    trailing: true,
  });

  const addOrRemoveKitFromCollection = useCallback(
    async (kitId: string, kitCollectionId: string, collectionContainsKit: boolean) => {
      setError(null);
      if (!collectionContainsKit) {
        const result = await addKitToCollection({ kitId, kitCollectionId });
        setError(result.error?.message);
      } else {
        const result = await removeKitFromCollection({ kitId, kitCollectionId });
        setError(result.error?.message);
      }
    },
    [addKitToCollection, removeKitFromCollection]
  );

  /* Rendering functions */

  function renderFilter() {
    if (!kits?.length) return null;

    return (
      <Fragment>
        <Flex
          px="xxl"
          py="l"
          background="grayLightest"
          borderBottom="default"
          justifyContent="space-between"
          flex="0 0 auto">
          <SearchField
            query={filterQuery}
            background="white"
            placeholder="Filter"
            fetchSuggestions={debouncedOnFilterResults}
            submitSearch={debouncedOnFilterResults}
            clearSearch={onFilterResults}
            autoFocus
          />
        </Flex>
      </Fragment>
    );
  }

  function renderKitSelection(kit: Kit) {
    const collectionContainsKit = kitIds.includes(kit.kitId);
    return (
      <KitItem key={kit.kitId}>
        <KitDetails>
          <KitCover cover src={kit.images.cover} icon="content.kit" iconFill="grayDark" />
          <KitName>{kit.name}</KitName>
          <PrivacyDetail>
            <Icon iconId={iconMap[kit.privacy]} size={24} mr="xs" />
            <Text textTransform="capitalize">{kit.privacy}</Text>
          </PrivacyDetail>
          <Checkbox
            id={kit.kitId}
            size="regular"
            truncate={false}
            label={undefined}
            type="checkbox"
            disabled={false}
            isSelected={collectionContainsKit}
            onClick={() =>
              addOrRemoveKitFromCollection(kit.kitId, kitCollectionId, collectionContainsKit)
            }
            styleOverrides={{ ml: "m" }}
          />
        </KitDetails>
      </KitItem>
    );
  }

  function renderSearchResults() {
    const results = filterKits(filterQuery, kits);

    return results.length ? (
      <Box>
        <Text font="ui.regularBold">Choose kits for your collection</Text>
        <Text mb="l">Add or remove kits using the checkboxes below.</Text>
        {results.map(renderKitSelection)}
      </Box>
    ) : (
      <EmptyState
        iconProps={{ iconId: "navigation.search", size: "60", fill: "gray" }}
        title="No results"
        subtitle="Try refining your search query"
        size="regular"
        styleOverrides={{ py: "20px" }}
      />
    );
  }

  function renderModalBody() {
    if (isLoading) {
      // Loading
      return <ActivityIndicator center />;
    } else if (loadingError) {
      // Loading error
      return (
        <EmptyState
          size="small"
          title="Unable to load collections"
          subtitle={loadingError.message}
        />
      );
    }
    if (!kits.length) {
      // Empty
      return (
        <EmptyState
          size="small"
          iconProps={{ iconId: "content.kit", size: 60, fill: "gray" }}
          title="No kits yet"
          subtitle="A Kit is a home for your visual language (e.g. Style Guide, UI Library)."
          extra={undefined}
          dropZoneProps={undefined}
          styleOverrides={{ py: "none" }}
        />
      );
    } else if (filterQuery) {
      // Filtered kits
      return renderSearchResults();
    } else {
      // All the kits
      return (
        <>
          <Text font="ui.regularBold">Choose kits for your collection</Text>
          <Text mb="l">Add or remove kits using the checkboxes below.</Text>
          <Box>{kits.map(renderKitSelection)}</Box>
        </>
      );
    }
  }

  return (
    <Fragment>
      <ModalHeader title={`Manage "${collectionName}" Kits`} />
      {renderFilter()}
      <ModalBody>
        {error && (
          <Notice
            id="error"
            button={undefined}
            secondaryButton={undefined}
            noticeStyle="error"
            message={error}
            mb="l"
          />
        )}
        {renderModalBody()}
      </ModalBody>
    </Fragment>
  );
};

export default ManageCollectionKitsModal;
