/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useCallback, useState, Fragment, useMemo, useEffect, useRef } from "react";
import _debounce from "lodash/debounce";
import {
  TabBar,
  Box,
  Flex,
  Button,
  ActivityIndicator,
  pluralize,
  Notice,
  SearchField,
  Text,
} from "@thenounproject/lingo-core";
import { isArraySubset } from "shared";
import _without from "lodash/without";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";

import {
  buildFigmaNodeLink,
  filterFigmaNodes,
  flattenFigmaComponents,
  flattenFigmaFile,
  generateFigmaNameMap,
  orderFigmaNodes,
} from "@helpers/figma";
import FigmaNodeResult from "./FigmaNodeResult";
import FigmaPageWrapper from "./FigmaPageWrapper";
import EmptyState from "../EmptyState";
import FigmaVirtualizedSearchResults from "./FigmaVirtualizedSearchResults";
import useCreateAssetsFromSource, {
  AssetSourceVendors,
} from "@redux/actions/items/useCreateAssetsFromSource";
import { useSelectSpace } from "@selectors/entities/spaces";
import useFigmaFileContents from "@redux/actions/figma/useFigmaFileContents";
import { type InsertPosition } from "@actions/uploads";
import useShowModal from "@redux/actions/useModals";

const tabs = {
  FRAMES: "Frames",
  COMPONENTS: "Components",
};

type Props = {
  url: string;
  insertPosition: InsertPosition;
};

const FigmaFramePickerModal = ({ url, insertPosition }: Props) => {
  const { dismissModal } = useShowModal();
  const space = useSelectSpace();
  const [error, setError] = useState(""),
    [loading, setLoading] = useState(true),
    [activeTab, setActiveTab] = useState(tabs.FRAMES),
    [expandedNodes, setExpandedNodes] = useState([]),
    [selectedNodes, setSelectedNodes] = useState([]),
    [figmaPageContent, setFigmaPageContent] = useState(null),
    [filterQuery, setFilterQuery] = useState();

  const [createAssetsFromSource] = useCreateAssetsFromSource();
  const [fetchFigmaFileContents] = useFigmaFileContents();

  const {
    figmaPages,
    figmaComponents,
    figmaComponentIds,
    figmaFrames,
    figmaFrameIds,
    figmaComponentsFlat,
    figmaNodeNameMap,
  } = useMemo(() => {
    let figmaPages = [],
      figmaComponents = [],
      figmaComponentsFlat = [],
      figmaFrames = [],
      figmaFrameIds = [],
      figmaComponentIds = [],
      figmaNodeNameMap = {};

    if (figmaPageContent) {
      figmaPages = figmaPageContent.pages;
      figmaFrames = flattenFigmaFile(figmaPageContent);
      figmaFrameIds = figmaFrames.map(s => s.node_id);
      figmaComponents = figmaPageContent.components;
      figmaComponentsFlat = flattenFigmaComponents(figmaPageContent);
      figmaComponentIds = figmaComponentsFlat.map(s => s.node_id);
      figmaNodeNameMap = generateFigmaNameMap([...figmaFrames, ...figmaComponentsFlat]);
    }
    return {
      figmaPages,
      figmaComponents,
      figmaComponentIds,
      figmaFrames,
      figmaFrameIds,
      figmaComponentsFlat,
      figmaNodeNameMap,
    };
  }, [figmaPageContent]);

  const allComponentsSelected = useMemo(() => {
    return isArraySubset(selectedNodes, figmaComponentIds);
  }, [figmaComponentIds, selectedNodes]);

  // MARK : Effects
  // -------------------------------------------------------------------------------

  useEffect(() => {
    setFilterQuery(undefined);
  }, [activeTab]);

  useEffect(() => {
    async function fetchContentsOnMount() {
      try {
        setLoading(true);
        const figmaContentResponse = await fetchFigmaFileContents({ url, spaceId: space.id });
        if (figmaContentResponse.error) {
          setError(figmaContentResponse.error.message);
        } else {
          const selectedNode = figmaContentResponse.response.result.selected_node;
          setFigmaPageContent(figmaContentResponse.response.result.content);
          setExpandedNodes(selectedNode ? [selectedNode] : []);
        }
        setLoading(false);
      } catch (_) {
        setError("Failed to fetch Figma page contents.");
        setLoading(false);
      }
    }
    void fetchContentsOnMount();
  }, [fetchFigmaFileContents, url, space.id]);

  const strings = useMemo(() => {
    return {
      title: figmaPageContent?.name || "Unnamed Figma File",
      label: "Share link",
      linkError: "Invalid Figma link",
      helper: "You can use a link to a frame, figma file or a specific page in a figma file",
      placeholder: "Paste a share link to a Figma file here",
      button: selectedNodes.length
        ? `Import ${selectedNodes.length} ${pluralize("asset", selectedNodes.length)}`
        : "Import",
    };
  }, [figmaPageContent?.name, selectedNodes.length]);

  // MARK : Handlers
  // -------------------------------------------------------------------------------

  const onFilterResults = useCallback(query => {
    setFilterQuery(query);
  }, []);

  const debouncedOnFilterResults = _debounce(onFilterResults, 250, {
    // Only run filter on final keystroke of interval
    leading: false,
    trailing: true,
  });

  const onSubmitSelection = useCallback(() => {
    if (!selectedNodes.length) return;
    const orderedNodes = orderFigmaNodes(selectedNodes, [...figmaFrameIds, ...figmaComponentIds]);
    const sources = orderedNodes.map(f => {
      return {
        source: "figma" as AssetSourceVendors.Figma,
        name: figmaNodeNameMap[f] || "Figma asset",
        url: buildFigmaNodeLink(figmaPageContent?.file_key, f),
      };
    });
    dismissModal();
    return createAssetsFromSource({ sources, insertPosition });
  }, [
    dismissModal,
    figmaComponentIds,
    figmaFrameIds,
    figmaNodeNameMap,
    figmaPageContent?.file_key,
    createAssetsFromSource,
    insertPosition,
    selectedNodes,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (loading) return true;
    if (!selectedNodes.length) return true;

    return false;
  }, [loading, selectedNodes.length]);

  const onToggleNodeExpanded = useCallback(
    node_id => {
      if (expandedNodes.includes(node_id)) {
        setExpandedNodes(_without(expandedNodes, node_id));
      } else setExpandedNodes([...expandedNodes, node_id]);
    },
    [expandedNodes]
  );

  const onSelectNode = useCallback(
    node_id => {
      if (selectedNodes.includes(node_id)) {
        setSelectedNodes(_without(selectedNodes, node_id));
      } else setSelectedNodes([...selectedNodes, node_id]);
    },
    [selectedNodes]
  );

  const onSelectAllComponents = useCallback(() => {
    if (allComponentsSelected) {
      setSelectedNodes(_without(selectedNodes, ...figmaComponentIds));
    } else setSelectedNodes([...new Set([...figmaComponentIds, ...selectedNodes])]);
  }, [allComponentsSelected, figmaComponentIds, selectedNodes]);

  const onSelectChildren = useCallback(
    nodes => {
      const shouldRemoveNodes = isArraySubset(selectedNodes, nodes);
      if (shouldRemoveNodes) {
        setSelectedNodes(_without(selectedNodes, ...nodes));
      } else setSelectedNodes([...new Set([...nodes, ...selectedNodes])]);
    },
    [selectedNodes]
  );

  // MARK : Rendering
  // -------------------------------------------------------------------------------

  function renderFilter() {
    return (
      <Fragment>
        <Flex
          px="xxl"
          py="l"
          background="grayLightest"
          borderBottom="default"
          justifyContent="space-between"
          flex="0 0 auto">
          <SearchField
            query={filterQuery}
            background="white"
            placeholder="Filter"
            fetchSuggestions={debouncedOnFilterResults}
            submitSearch={debouncedOnFilterResults}
            clearSearch={() => setFilterQuery(undefined)}
            autoFocus
          />
        </Flex>
      </Fragment>
    );
  }

  function renderLoadingState() {
    return (
      <>
        <Box height="100px" pt="xl" mb="m">
          <ActivityIndicator center />
        </Box>
        <Box pb="xl">
          <Text textAlign="center" color="grayDarkest">
            Loading your Figma file, this may take a while
          </Text>
        </Box>
      </>
    );
  }

  function renderModalBody() {
    if (loading) return renderLoadingState();
    if (filterQuery) return renderSearchResults();
    return activeTab === tabs.COMPONENTS ? renderComponents() : renderFrames();
  }
  function renderSearchResults() {
    const nodes = activeTab === tabs.COMPONENTS ? figmaComponents : figmaFrames;
    const results = filterFigmaNodes(filterQuery, nodes);
    const props = {
      results,
      onSelect: onSelectNode,
      selectedNodes,
    };
    return results.length ? (
      <FigmaVirtualizedSearchResults {...props} />
    ) : (
      <EmptyState
        iconProps={{ iconId: "navigation.search", size: "60", fill: "gray" }}
        title="No results"
        subtitle="Try refining your search query"
        styleOverrides={{ py: "20px" }}
      />
    );
  }

  function renderComponents() {
    if (!figmaComponents.length) return renderEmptyResultsPage("components");
    const nodes = figmaComponents.map(node => {
      const nodeProps = {
        data: node,
        onSelect: onSelectNode,
        selectedNodes,
        expandedNodes,
        onSelectChildren,
        onToggleNodeExpanded,
      };
      return <FigmaNodeResult key={node.node_id} {...nodeProps} />;
    });
    return (
      <>
        <Flex justifyContent="flex-end" width="100%" mb="m">
          <Button
            onClick={onSelectAllComponents}
            buttonStyle="tertiary"
            icon={allComponentsSelected ? "action.checkbox-checked" : "action.checkbox-unchecked"}
            text="Select all"
            size="small"
          />
        </Flex>
        {nodes}
      </>
    );
  }

  function renderFrames() {
    if (!figmaPages.length) return renderEmptyResultsPage("frames");

    function renderFrameNodes(nodes) {
      return nodes.map(node => {
        const nodeProps = {
          data: node,
          onSelect: onSelectNode,
          selectedNodes,
          expandedNodes,
          onSelectChildren,
          onToggleNodeExpanded,
        };
        return <FigmaNodeResult key={node.node_id} {...nodeProps} />;
      });
    }

    return figmaPages.map(page => {
      return (
        <FigmaPageWrapper
          name={page.name}
          key={page.node_id}
          node_id={page.node_id}
          expanded={expandedNodes.includes(page.node_id)}
          onTogglePageExpanded={onToggleNodeExpanded}>
          <Box>{renderFrameNodes(page.children)}</Box>
        </FigmaPageWrapper>
      );
    });
  }

  function renderEmptyResultsPage(type) {
    return (
      <EmptyState
        title={`No ${type} found`}
        subtitle=""
        iconProps={{ iconId: "info.figma", size: "60", fill: "gray" }}
      />
    );
  }

  function renderError() {
    if (error) return <Notice noticeStyle="error" message={error} />;
    return null;
  }

  function renderTabBar() {
    const tabProps = {
      [tabs.FRAMES]: {
        text: tabs.FRAMES,
        subtext: `(${figmaFrames.length})`,
        selected: activeTab === tabs.FRAMES,
        disabled: figmaFrames.length === 0,
        onClick: () => setActiveTab(tabs.FRAMES),
      },
      [tabs.COMPONENTS]: {
        text: tabs.COMPONENTS,
        subtext: `(${figmaComponentsFlat?.length || "0"})`,
        selected: activeTab === tabs.COMPONENTS,
        disabled: figmaComponentsFlat?.length === 0,
        onClick: () => setActiveTab(tabs.COMPONENTS),
      },
    };
    return (
      <TabBar separator styleOverrides={{ mt: "m" }}>
        <TabBar.Item {...tabProps[tabs.FRAMES]} />
        <TabBar.Item {...tabProps[tabs.COMPONENTS]} />
      </TabBar>
    );
  }

  return loading ? (
    renderLoadingState()
  ) : (
    <>
      <ModalHeader title={strings.title} styleOverrides={{ borderBottom: "none" }}>
        {renderTabBar()}
      </ModalHeader>
      {renderFilter()}
      <ModalBody pt="m" flex="0 1 100%">
        {renderError()}
        {renderModalBody()}
      </ModalBody>
      <ModalFooter
        primary={{
          text: strings.button,
          onClick: onSubmitSelection,
          disabled: isButtonDisabled,
        }}
      />
    </>
  );
};

export default FigmaFramePickerModal;
