import React, { useState, Fragment, useCallback, ComponentProps } from "react";
import styled from "styled-components";
import {
  Text,
  Box,
  Button,
  Flex,
  TabBar,
  Notice,
  ActivityIndicator,
  utils,
  SpacePlan,
  SpacePermission,
  Icon,
  Grid,
  Plan,
  formatPrice,
  estimatePlanCosts,
  type PriceEstimation,
  Tooltip,
} from "@thenounproject/lingo-core";
import useAvailablePlans from "@redux/actions/billing/useAvailablePlans";

import EmptyState from "../EmptyState";
import plansData from "../../marketing/data/pricing_SELF_SERVE";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type PlanCardProps = { recommended: boolean };
const PlanCard = styled(Flex).attrs<PlanCardProps>({
  mx: "4px",
  flex: "1 1 0",
  flexDirection: "column",
  textAlign: "left",
  color: "grayDarkest",
  overflow: "hidden",
  background: "white",
  borderRadius: "default",
})<PlanCardProps>`
  border: ${props =>
    props.recommended
      ? `1px ${props.theme.primaryColor} solid`
      : `1px solid ${utils.getColor("grayLight")}`};

  h2 {
    color: ${utils.getColor("black")};
  }

  @media (max-width: 768px) {
    width: calc(50% - 8px);
    flex: none;
    margin-top: 24px;
  }
`;

const PlanCardFooter = styled(Flex).attrs({
  p: "m",
  borderTop: "default",
  background: "grayLightest",
  textAlign: "left",
  justifyContent: "space-between",
  alignItems: "center",
})``;

const Badge = styled(Box).attrs<Partial<PlanCardProps>>(props => ({
  position: "absolute",
  right: 0,
  top: 0,
  px: "s",
  py: "xs",
  background: props.recommended ? props.theme.primaryColor : "grayLighter",
}))<Partial<PlanCardProps>>`
  border-radius: 0 0 0 4px;
`;

export type SelectedPlan = Plan & { interval: "month" | "year" };

type Props = {
  spaceId?: number;
  recommendedPlan?: string;
};

const SelectPlanModal: React.FC<Props> = ({ spaceId, recommendedPlan }) => {
  const { showModal } = useModals();
  const space = useSelectSpace(spaceId);
  const currentPlan = space?.plan;

  const defaultInterval =
    space.plan?.interval ?? (space.subscription?.plan?.includes("year") ? "year" : "month");
  const [selectedInterval, setSelectedInterval] = useState<"year" | "month">(defaultInterval);

  const {
    status,
    error,
    data: { plans } = {},
  } = useAvailablePlans({ spaceId: space.id }, { refetchOnMount: true });

  const canManageBilling = space?.access?.permissions?.includes(SpacePermission.manageBilling);

  const requestEnterprise = useCallback(() => {
    showModal(ModalTypes.ENTERPRISE_REQUEST, { spaceId: space.id }, true);
  }, [showModal, space.id]);

  const continueWithPlan = useCallback(
    (plan: Plan) => {
      showModal(
        ModalTypes.CHANGE_SUBSCRIPTION,
        {
          spaceId: space.id,
          planIdentifier: plan.planIdentifier,
          interval: plan.interval,
        },
        true
      );
    },
    [showModal, space.id]
  );

  function toggleInterval(interval: "year" | "month") {
    const newInterval = interval;
    setSelectedInterval(newInterval);
  }

  /**
   * Rendering
   */

  function renderContent() {
    if (error) return <EmptyState title="Something went wrong" subtitle={error.message} />;
    if (status === "pending") {
      return (
        <Box height="150">
          <ActivityIndicator center />
        </Box>
      );
    }
    const enterprisePlan = {
      planIdentifier: "enterprise",
      interval: selectedInterval,
      type: "preset",
      planName: "Enterprise",
      featureGroup: "enterprise",
      costPerEditor: 0,
      platformFee: 0,
      trialDays: null,
      usage: null,
    } as Plan;
    const visiblePlans = [
      ...Object.values(plans).map(plan => plan[selectedInterval]),
      enterprisePlan,
    ] as Plan[];

    return (
      <Fragment>
        <Flex flexShrink="0" justifyContent="space-between" flexWrap="wrap" width="100%">
          {visiblePlans.map(plan => renderPlanCard({ plan, currentPlan }))}
        </Flex>
      </Fragment>
    );
  }

  function renderPlanCard({ plan, currentPlan }: { plan: Plan; currentPlan: SpacePlan }) {
    const { planName, planIdentifier } = plan;
    const estimation = estimatePlanCosts(space, plan);
    const isCurrent = currentPlan?.planName === planName;
    const isRecommended = !isCurrent && recommendedPlan === planIdentifier;

    return (
      <PlanCard
        data-testid="plan-card"
        position="relative"
        key={planName}
        recommended={isRecommended}>
        {renderCardHeader({ plan, currentPlan, estimation })}
        {isCurrent && (
          <Badge>
            <Text font="ui.smallBold">Current plan</Text>
          </Badge>
        )}

        {isRecommended && (
          <Badge recommended>
            <Text color="white" font="ui.smallBold">
              Recommended
            </Text>
          </Badge>
        )}
        <Box p="m">
          <Text mb="s" font="ui.regularBold" color="grayDarkest">
            Features
          </Text>
          {renderUniqueFeatures({ plan })}
        </Box>
        <Box flex="1" />
        <Box borderTop="default" mt="l">
          {renderBreakdown(plan, estimation)}
          {renderCardFooter({ plan, estimation })}
        </Box>
      </PlanCard>
    );
  }

  function renderCardHeader({
    plan,
    currentPlan,
    estimation,
  }: {
    plan: Plan;
    currentPlan: SpacePlan;
    estimation: PriceEstimation;
  }) {
    const { planName, planIdentifier } = plan;
    const currentPlanData = plansData.plans.find(p => p.id === currentPlan?.planIdentifier);
    const isCurrent = currentPlan?.planIdentifier === planIdentifier;
    const cancelledPlan = currentPlan?.cancelAtPeriodEnd || currentPlan?.status === "canceled";
    let priceLabel = "Custom pricing";
    if (plan.planName !== "Enterprise") {
      const platformFee = formatPrice(estimation.platformFee.total, { round: true });
      const editorCost = formatPrice(plan.costPerEditor, { round: true });
      priceLabel = `${platformFee}/mo + ${editorCost}/editor/mo`;
    }

    const getButtonProps = (): Pick<
      ComponentProps<typeof Button>,
      "text" | "buttonStyle" | "onClick"
    > => {
      if (plan.planIdentifier === "enterprise" || space.subscription?.plan.includes("ent")) {
        // Always show contact us for the enterprise plan
        // Also show contact us if the space is current on an enterprise plan
        return { text: "Contact us", buttonStyle: "secondary", onClick: requestEnterprise };
      } else if (
        currentPlanData?.previous_plan === planName ||
        (currentPlanData?.id === "enterprise" && !planName.includes("ent"))
      ) {
        // if the plan is a previous plan, show a downgrade button
        return {
          text: "Downgrade",
          buttonStyle: "secondary",
          onClick: () => continueWithPlan(plan),
        };
      }
      // if viewing the annual / monthly version of current plan show switch button
      else if (isCurrent && currentPlan?.interval !== selectedInterval) {
        const interval = currentPlan?.interval === "year" ? "monthly" : "annual";
        return {
          text: `Switch to ${interval}`,
          buttonStyle: "secondary",
          onClick: () => continueWithPlan(plan),
        };
      } else if (isCurrent) {
        return {
          text: "Your plan",
          buttonStyle: "primary",
          onClick: () => {
            if (!cancelledPlan) return;
            continueWithPlan(plan);
          },
        };
      } else {
        return {
          text: "Upgrade",
          buttonStyle: "primary",
          onClick: () => continueWithPlan(plan),
        };
      }
    };
    return (
      <Flex borderBottom="default" p="m" gap="24px" flexDirection="column">
        <Box>
          <Text style={{ marginBottom: 0 }} as="h2" font="ui.title" mb="xs">
            {planName}
          </Text>
          <Text color="grayDarkest">{priceLabel}</Text>
        </Box>
        <Button
          {...getButtonProps()}
          fullWidth
          disabled={
            (!cancelledPlan && isCurrent && currentPlan?.interval === selectedInterval) ||
            !canManageBilling
          }
        />
      </Flex>
    );
  }

  function renderCardFooter({ plan, estimation }: { plan: Plan; estimation: PriceEstimation }) {
    const yourPlan =
      currentPlan?.type !== "custom" &&
      currentPlan?.planName === plan?.planName &&
      currentPlan?.interval === selectedInterval;

    if (plan.featureGroup === "enterprise") return;

    return (
      <PlanCardFooter>
        <Text font="ui.regularSemiBold">{yourPlan ? "Current Price" : "New price"}</Text>
        <Flex alignItems="center">
          <Text font="ui.title">{formatPrice(estimation.total)}</Text>
          <Text font="ui.regularBold">/mo</Text>
        </Flex>
      </PlanCardFooter>
    );
  }

  function renderUniqueFeatures({ plan }: { plan: Plan }) {
    const data = plansData.plans.find(p => p.name === plan.planName);
    const renderWithIcon = (text: string) => {
      return (
        <Flex gap="8px" alignItems="center">
          <Flex
            width="20px"
            height="20px"
            justifyContent="center"
            borderRadius="10px"
            background="primary">
            <Icon style={{ marginTop: "1px" }} iconId="checkmark" fill="white" size="16" />
          </Flex>
          <Text>{text}</Text>
        </Flex>
      );
    };
    return (
      <Flex flexDirection="column" gap="8px">
        {data.previous_plan ? renderWithIcon(`All ${data.previous_plan} features`) : null}
        {data.storage ? renderWithIcon(`${data.storage} GB Storage`) : null}
        {data.uniqueFeatures?.map(f => (
          <Flex key={f.name.replace(" ", "-")} gap="8px">
            {renderWithIcon(f.name)}
          </Flex>
        ))}
      </Flex>
    );
  }

  function renderBreakdown(plan: Plan, estimation: ReturnType<typeof estimatePlanCosts>) {
    if (plan.featureGroup === "enterprise") return;
    const rows = [
      {
        label: "Platform fee",
        value: null,
        cost: formatPrice(estimation.platformFee.total),
      },
      {
        label: "Editors",
        value: estimation.editors.count,
        cost: formatPrice(estimation.editors.total),
      },
      {
        label: "Consumers",
        value: estimation.consumers.count.toLocaleString(),
        cost: formatPrice(estimation.consumers.total, { include: true }),
      },
      {
        label: "Link requests",
        value: estimation.dlRequests.count.toLocaleString(),
        cost: formatPrice(estimation.dlRequests.total, { include: true }),
      },
      {
        label: "Link bandwidth",
        value: `${estimation.dlBandwidth.count} GB`,
        cost: formatPrice(estimation.dlBandwidth.total, { include: true }),
      },
    ];
    return (
      <Flex flexDirection="column" gap="8px" p="m">
        <Flex alignItems="center" gap="8px">
          <Text font="ui.regularBold">Plan Cost Breakdown</Text>
          <Tooltip.InfoTip fill="grayDark" direction="right">
            <Box maxWidth={250}>
              <Text color="white" font="ui.small" lineHeight="150%">
                This breakdown shows your estimated cost based on your selected plan and usage.
              </Text>
              <Button
                buttonStyle="tertiary"
                themeOverrides={{ primaryColor: "white", primaryColorDark: "white" }}
                fontStyle="ui.smallBold"
                text="Learn more."
                newWindow
                link="https://help.lingoapp.com/en/articles/2418503-how-much-do-i-need-to-pay-for-lingo"
              />
            </Box>
          </Tooltip.InfoTip>
        </Flex>
        {rows.map(({ label, value, cost }, index) => (
          <Grid key={index} templateColumns={value || value === 0 ? "2fr 1fr 1fr" : "1fr 1fr"}>
            <Text color="grayDarkest" font="ui.regular">
              {label}
            </Text>
            {(value || value === 0) && <Text font="ui.regular">{value}</Text>}
            <Text textAlign="right" font="ui.regularBold">
              {cost}
            </Text>
          </Grid>
        ))}
      </Flex>
    );
  }

  function renderContactOwnerNotice() {
    return (
      <Notice
        mb="l"
        message={
          <Text font="ui.small">
            You don&apos;t have permission to update this plan. Please speak to the owner of your
            account to continue.
          </Text>
        }
        noticeStyle="warning"
      />
    );
  }

  return (
    <Fragment>
      <ModalHeader title="Change plan">
        <TabBar>
          <TabBar.Item
            text="Monthly"
            selected={selectedInterval === "month"}
            onClick={() => toggleInterval("month")}
          />
          <TabBar.Item
            text="Annual (-20%)"
            selected={selectedInterval === "year"}
            onClick={() => toggleInterval("year")}
            data-test="toggle-label"
          />
        </TabBar>
      </ModalHeader>
      <ModalBody pl="l" pr="l">
        {!canManageBilling && status === "fulfilled" && renderContactOwnerNotice()}
        {renderContent()}
      </ModalBody>
    </Fragment>
  );
};

export default SelectPlanModal;
