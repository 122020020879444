import React from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Flex,
  Text,
  Notice,
  Icon,
  Checkbox,
  ImageView,
  pluralize,
  PrivacyLevel,
  Space,
  Kit,
  Tooltip,
} from "@thenounproject/lingo-core";

import { selectSpaceKitCollectionItems } from "@selectors/entities/kitCollectionItems";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import EmptyState from "../../../EmptyState";
import { useAppSelectorV1 } from "@redux/hooks";
import useUpdateKitPortalAccess from "@redux/actions/kits/useUpdateKitPortalAccess";
import useKitCollections from "@actions/kitCollections/useKitCollections";
import useKits from "@redux/actions/kits/useKits";

const KitItem = styled(Box).attrs({
  as: "li",
  mb: "m",
  borderBottom: "default",
})`
  list-style: none;
  align-items: center;
`;

const KitDetails = styled(Flex).attrs({
  mb: "m",
  justifyContent: "space-between",
  alignItems: "center",
})``;

const KitCover = styled(ImageView).attrs({
  mr: "m",
  height: "50px",
  width: "50px",
  flexBasis: "50px",
  flexShrink: 0,
  borderRadius: "default",
  alignItems: "center",
  justifyContent: "center",
  background: "grayLighter",
})`
  & svg {
    margin-right: 0 !important;
  }
`;

const KitInfo = styled(Box).attrs({
  flexDirection: "column",
  textAlign: "left",
  flex: 0.6,
  truncate: true,
})``;

const KitName = styled(Text).attrs({ font: "ui.regularBold" })``;
const KitCollectionCount = styled(Text).attrs({ font: "ui.small", color: "grayDarkest" })``;

const PrivacyDetail = styled(Flex).attrs({
  flex: 0.4,
  alignItems: "center",
})``;

function warningMessage(kitPrivacy, spacePrivacy) {
  const learnMore = (
    <Button
      text="Learn more."
      buttonStyle="tertiary"
      fontStyle="ui.smallBold"
      tintColor="white"
      newWindow
      link="https://help.lingoapp.com/en/collections/409163-distributing-your-design-language"
    />
  );

  switch (spacePrivacy) {
    case PrivacyLevel.password:
      switch (kitPrivacy) {
        case PrivacyLevel.private:
          return "This kit is private but anyone in the portal can open it.";
        case PrivacyLevel.password:
          return "This kit has a password but anyone in the portal can open it.";
        default:
          return null;
      }
    case PrivacyLevel.public:
      switch (kitPrivacy) {
        case PrivacyLevel.private:
          return <>Only members of your space with access to this kit can open it. {learnMore}</>;
        case PrivacyLevel.password:
          return <>People will need to enter the kit password to open this kit. {learnMore}</>;
        default:
          return null;
      }
    default:
      return null;
  }
}

type Props = {
  space: Space;
};

const ManagePortalKits: React.FC<Props> = ({ space }) => {
  const [updateKitPortalAccess] = useUpdateKitPortalAccess();
  // We don't actually need the collections but this ensures the collection
  // items are fetched and in the store
  useKitCollections({ spaceId: space.id });
  const { data } = useKits({ spaceId: space.id });
  const { kits = [] } = data || {};
  const kitCollectionItems = useAppSelectorV1(selectSpaceKitCollectionItems) ?? [];
  const kitCollectionItemIds = kitCollectionItems.map(kci => kci.kit);

  const iconMap = {
    [PrivacyLevel.public]: "globe",
    [PrivacyLevel.private]: "users",
    [PrivacyLevel.password]: "globe-lock",
  };

  const renderKitSelection = (kit: Kit) => {
    const warning = kit.portalAccess ? warningMessage(kit.privacy, space.privacy) : null;
    const collectionCount = kitCollectionItemIds.filter(k => k === kit.kitId).length;
    return (
      <KitItem data-testid="kit-item" key={kit.kitId}>
        <KitDetails>
          <KitCover
            alt={`${kit.name} cover image`}
            cover
            src={kit.images.cover}
            icon="content.kit"
            iconFill="grayDark"
          />
          <Flex flex="1">
            <KitInfo>
              <KitName font="ui.regularBold">{kit.name}</KitName>
              <KitCollectionCount font="ui.small" color="grayDarkest">
                In {collectionCount} {pluralize("collection", collectionCount)}
              </KitCollectionCount>
            </KitInfo>
            <PrivacyDetail>
              <Icon flexShrink="0" iconId={iconMap[kit.privacy]} size={24} mr="xs" />
              <Text textTransform="capitalize">{kit.privacy}</Text>
              {warning ? (
                <Tooltip.InfoTip iconId="warning" fill={"warning"}>
                  {warning}
                </Tooltip.InfoTip>
              ) : null}
            </PrivacyDetail>
          </Flex>
          <Checkbox
            id={`checkbox-${kit.kitId}`}
            isSelected={kit.portalAccess}
            onClick={() =>
              updateKitPortalAccess({
                kitId: kit.kitId,
                spaceId: space.id,
                portalAccess: !kit.portalAccess,
              })
            }
            styleOverrides={{ ml: "m" }}
          />
        </KitDetails>
      </KitItem>
    );
  };

  function renderEmpty() {
    return (
      <EmptyState
        size="small"
        iconProps={{ iconId: "content.kit", size: 60, fill: "gray" }}
        title="No Kits"
        subtitle="There are no Kits in your space. Create a Kit to add it to your Brand Portal."
        styleOverrides={{ py: "none" }}
      />
    );
  }

  function renderKits() {
    return (
      <>
        <Notice
          noticeStyle="info"
          flexShrink="0"
          mb="l"
          message={
            space.privacy === PrivacyLevel.public
              ? "Your portal is public."
              : "Your portal is password protected"
          }
        />
        <Text font="ui.regularBold">Choose kits to share</Text>
        <Text mb="l">The Kits you select here will be included in your Brand Portal.</Text>

        <div>{kits.map(renderKitSelection)}</div>
      </>
    );
  }

  return (
    <>
      <ModalHeader title="Manage Portal Kits" />
      <ModalBody>{kits.length ? renderKits() : renderEmpty()}</ModalBody>
    </>
  );
};

export default ManagePortalKits;
