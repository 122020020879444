import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import usePortal from "@redux/actions/portals/usePortal";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";
import useSpace from "@redux/actions/spaces/useSpace";
import { ActivityIndicator, Box, buildURL, SpacePermission } from "@thenounproject/lingo-core";
import React, { useCallback } from "react";
import EmptyState from "../../EmptyState";
import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import { DomainToggleBox } from "../../spaces/settings/SettingsDomain";
import UpsellTooltip from "../../spaces/settings/UpsellTooltip";

type Props = {
  portalId: string | number;
};

const PortalDomainModal: React.FC<Props> = ({ portalId }) => {
  const { data: portal, isLoading, error, refresh } = usePortal({ portalId });

  const { data: space } = useSpace({ spaceId: portal?.spaceId });

  const { openUpgradeModal, accessStatus } = useUpsell(SpacePermission.manageCustomDomain);
  const insufficientPlan = accessStatus === AccessStatus.insufficientPlan;

  const fullUrl = buildURL(`/p/${portal?.shortId ?? portalId}`, { space });

  const [updatePortal] = useUpdatePortal();
  const submitSubdomain = useCallback(
    async (newDomain: string = null) => {
      return await updatePortal({
        id: portalId,
        subdomain: newDomain,
      });
    },
    [updatePortal, portalId]
  );

  function renderContent() {
    if (isLoading) {
      return <ActivityIndicator center height="200px" />;
    } else if (error) {
      return (
        <EmptyState
          title="Error"
          subtitle="An error occurred while loading the portal"
          button={{ text: "Retry", onClick: refresh }}
        />
      );
    }
    if (portal) {
      return (
        <>
          {insufficientPlan && (
            <UpsellTooltip
              source="settings-domain-togglebox"
              onClick={openUpgradeModal}
              featureName="Custom domain"
            />
          )}
          <Box data-testid="portal-settings-domain" data-tooltip-source="settings-domain-togglebox">
            <DomainToggleBox
              domain={portal.domain}
              subdomain={portal.subdomain}
              showUpdagdeBadge={insufficientPlan}
              fullUrl={fullUrl}
              onSave={submitSubdomain}
            />
          </Box>
        </>
      );
    }
  }

  return (
    <>
      <ModalHeader title="Edit Domain" />
      <ModalBody>{renderContent()}</ModalBody>
    </>
  );
};

export default PortalDomainModal;
