import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import {
  Flex,
  Text,
  Button,
  Notice,
  pluralize,
  Kit,
  KitCollectionItem,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalBody from "../../../components/ModalBody";
import EmptyState from "../../../components/EmptyState";

import useAddKitToCollection from "@redux/actions/kitCollections/useAddKitToCollection";
import useRemoveKitFromCollection from "@redux/actions/kitCollections/useRemoveKitFromCollection";
import useKitCollections from "@redux/actions/kitCollections/useKitCollections";

const Item = styled(Flex).attrs({
  as: "li",
  mb: "m",
  borderBottom: "default",
  width: "100%",
  alignItems: "center",
})`
  list-style: none;
  align-items: center;
`;

const Details = styled(Flex).attrs({
  mb: "m",
  justifyContent: "space-between",
  alignItems: "center",
  width: "50%",
})``;

const Name = styled(Text).attrs({
  textAlign: "left",
  font: "ui.regularBold",
  flexBasis: "200px",
  truncate: true,
})``;

const Count = styled(Flex).attrs({
  width: "50px",
  alignItems: "center",
})``;

const ButtonContainer = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "flex-end",
  width: "50%",
})``;

type Props = {
  kit: Pick<Kit, "kitId" | "name" | "spaceId">;
};

const ManageKitCollectionsModal: React.FC<Props> = ({ kit: { kitId, name: kitName, spaceId } }) => {
  const { data: kitCollections, isLoading, error: loadingError } = useKitCollections({ spaceId });
  const [error, setError] = useState(null);

  const [addKitToCollection] = useAddKitToCollection(),
    [removeKitFromCollection] = useRemoveKitFromCollection();

  const addOrRemoveKitFromCollection = useCallback(
    async (kitId: string, kitCollectionId: string, kitInCollection: boolean) => {
      setError(null);
      if (!kitInCollection) {
        const result = await addKitToCollection({ kitId, kitCollectionId });
        setError(result.error?.message);
      } else {
        const result = await removeKitFromCollection({ kitId, kitCollectionId });
        setError(result.error?.message);
      }
    },
    [addKitToCollection, removeKitFromCollection]
  );

  const collectionsIds = kitCollections?.reduce((res, kc) => {
    const includesKit = (kc.items as KitCollectionItem[]).some(item =>
      typeof item.kit === "string" ? item.kit === kitId : item.kit.kitId === kitId
    );
    if (includesKit) res.push(kc.uuid);
    return res;
  }, [] as string[]);

  function renderCollectionRows() {
    return kitCollections.map(kc => {
      const kitInCollection = collectionsIds?.includes(kc.uuid);

      const strings = kitInCollection
        ? { button: "Remove from collection" }
        : { button: "Add to collection" };

      const onClick = () => addOrRemoveKitFromCollection(kitId, kc.uuid, kitInCollection);
      return (
        <Item key={kc.uuid}>
          <Details>
            <Name>{kc.name}</Name>
            <Count>
              <Text>
                {kc.counts.kits} {pluralize("kit", kc.counts.kits)}
              </Text>
            </Count>
          </Details>
          <ButtonContainer>
            <Button buttonStyle="tertiary" text={strings.button} onClick={onClick} />
          </ButtonContainer>
        </Item>
      );
    });
  }

  function renderModalBody() {
    if (isLoading) {
      return <ActivityIndicator center />;
    } else if (loadingError) {
      return (
        <EmptyState
          size="small"
          title="Unable to load collections"
          subtitle={loadingError.message}
        />
      );
    } else if (!kitCollections.length) {
      return (
        <EmptyState
          size="small"
          title="No collections yet"
          subtitle="A collection adds more organization to your space by grouping related kits together."
        />
      );
    }
    return renderCollectionRows();
  }

  return (
    <Fragment>
      <ModalHeader title={`Add "${kitName}" to Collections`} />
      <ModalBody>
        {error && <Notice id="error" noticeStyle="error" message={error} mb="l" />}
        {renderModalBody()}
      </ModalBody>
    </Fragment>
  );
};

export default ManageKitCollectionsModal;
