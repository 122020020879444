import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import {
  Flex,
  Box,
  Text,
  Icon,
  Checkbox,
  ImageView,
  Notice,
  Kit,
  PrivacyLevel,
  PortalItemType,
  ActivityIndicator,
  Tooltip,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalBody from "../../../components/ModalBody";
import ModalFooter from "../../../components/ModalFooter";
import EmptyState from "../../../components/EmptyState";

import useKits from "@actions/kits/useKits";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import usePortal from "@redux/actions/portals/usePortal";
import useCreatePortalItems, {
  ItemData,
  InsertPosition,
} from "@redux/actions/portalItems/useCreatePortalItems";
import useShowModal from "@redux/actions/useModals";
import usePortalKits from "@redux/actions/portals/usePortalKits";

const KitItem = styled(Box).attrs({
  as: "li",
  mb: "m",
  borderBottom: "default",
})`
  list-style: none;
  align-items: center;
`;

const KitDetails = styled(Flex).attrs({
  mb: "m",
  justifyContent: "space-between",
  alignItems: "center",
})``;

const KitCover = styled(ImageView).attrs({
  mr: "m",
  height: "50px",
  width: "50px",
  flexBasis: "50px",
  flexShrink: 0,
  borderRadius: "default",
  alignItems: "center",
  justifyContent: "center",
  background: "grayLighter",
})``;

const KitName = styled(Text).attrs({
  textAlign: "left",
  flexGrow: 0.6,
  font: "ui.regularBold",
  flexBasis: "100px",
  truncate: true,
})``;

const PrivacyDetail = styled(Flex).attrs({
  flexGrow: 0.4,
  width: "50px",
  alignItems: "center",
})``;

type Props = {
  portalId: string;
  insertPosition: InsertPosition;
};

const AddExistingKitModal: React.FC<Props> = ({ portalId, insertPosition }) => {
  const iconMap = {
    [PrivacyLevel.public]: "globe",
    [PrivacyLevel.private]: "users",
    [PrivacyLevel.password]: "globe-lock",
  };

  const [createPortalItems, { isProcessing, error }] = useCreatePortalItems(),
    space = useSelectSpace(),
    { dismissModal } = useShowModal();

  const { data: portal, isLoading: isLoadingPortal, error: portalError } = usePortal({ portalId });
  const {
    data: kitsData,
    isLoading: isLoadingKit,
    error: kitLoadError,
  } = useKits({ spaceId: space.id });
  const { kits = [] } = kitsData || {};

  const { data } = usePortalKits({ portalId: portal?.id });
  const kitsInsidePortal = data?.kits || [];

  const isLoading = isLoadingKit || isLoadingPortal,
    loadingError = kitLoadError || portalError;

  const portalName = portal?.name;

  const [kitsToAdd, setKitsToAdd] = useState([]);

  const handleAddKitsToPortal = useCallback(async () => {
    const items: ItemData[] = kitsToAdd.map((kitId: string) => ({
      type: PortalItemType.kit,
      kitId,
    }));
    const result = await createPortalItems({
      items,
      portalId: portal.id,
      insertPosition,
    });
    if (result.isSuccess) {
      dismissModal();
    }
  }, [createPortalItems, kitsToAdd, portal, dismissModal, insertPosition]);

  const handleCheckboxClick = useCallback(
    (kitId: string) => {
      if (kitsToAdd.includes(kitId)) {
        setKitsToAdd(kitsToAdd.filter(id => id !== kitId));
      } else {
        setKitsToAdd([...kitsToAdd, kitId]);
      }
    },
    [kitsToAdd]
  );

  function renderKitSelection(kit: Kit) {
    const portalAlreadyHasKit = kitsInsidePortal.some(k => k.kitId === kit.kitId);

    return (
      <KitItem key={kit.kitId}>
        <KitDetails>
          <KitCover cover src={kit.images.cover} icon="content.kit" iconFill="grayDark" />
          <KitName>{kit.name}</KitName>
          <PrivacyDetail>
            <Icon iconId={iconMap[kit.privacy]} size={24} mr="xs" />
            <Text textTransform="capitalize">{kit.privacy}</Text>
          </PrivacyDetail>
          <Box data-tooltip-source={kit.kitId}>
            <Checkbox
              id={kit.kitId}
              size="regular"
              truncate={false}
              label={undefined}
              type="checkbox"
              disabled={false}
              isSelected={kitsToAdd.includes(kit.kitId)}
              onClick={() => handleCheckboxClick(kit.kitId)}
              styleOverrides={{ ml: "m" }}
            />
            {portalAlreadyHasKit && (
              <Tooltip direction={Tooltip.Direction.Left} source={kit.kitId}>
                This kit already in portal: {portal.name}
              </Tooltip>
            )}
          </Box>
        </KitDetails>
      </KitItem>
    );
  }

  function renderModalBody() {
    if (isLoading) {
      // Loading
      return <ActivityIndicator center />;
    } else if (loadingError) {
      // Loading error
      return (
        <EmptyState size="small" title="Unable to load kits" subtitle={loadingError.message} />
      );
    }
    if (!kits.length) {
      // Empty
      return (
        <EmptyState
          size="small"
          iconProps={{ iconId: "content.kit", size: 60, fill: "gray" }}
          title="No kits yet"
          subtitle="A Kit is a home for your visual language (e.g. Style Guide, UI Library)."
          extra={undefined}
          dropZoneProps={undefined}
          styleOverrides={{ py: "none" }}
        />
      );
    } else {
      // All the kits
      return (
        <>
          <Box>{kits.map(renderKitSelection)}</Box>
        </>
      );
    }
  }

  return (
    <Fragment>
      <ModalHeader title={`Add Kits to ${portalName}`} />
      <ModalBody>
        {error && (
          <Notice
            id="error"
            button={undefined}
            secondaryButton={undefined}
            noticeStyle="error"
            message={error.message}
            mb="l"
          />
        )}
        {renderModalBody()}
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing ? "Adding Kits..." : "Add Kits",
          onClick: handleAddKitsToPortal,
          disabled: isProcessing || !kitsToAdd.length,
        }}
      />
    </Fragment>
  );
};

export default AddExistingKitModal;
